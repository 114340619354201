import React, {useState} from "react";
import {Answer, PersonalInformationQuestion, Question} from "../models";
import {phoneNumberValidator} from "../../validators";
import {sendEvent} from "../../bots/BotsHelpers";

export function PhoneNumberFormRender(props: { question: PersonalInformationQuestion, answerCallback: any }) {
    const [phoneNumber, setPhoneNumber] = useState("")
    const [error, setError] = useState("")

    function updateValue(ev: React.ChangeEvent<HTMLInputElement>) {
        let validation = phoneNumberValidator(ev.target.value)
        if (validation == true) {
            setError("")
            setPhoneNumber(ev.target.value)
        } else {
            setError(validation)
            setPhoneNumber("")
        }
    }

    return <>
        <div style={{minHeight: '40vh'}}>
            <p className="font-size-xl font-weight-bold text-center">{props.question.text}</p>
            {props.question.description &&
                <p className="font-size-l font-weight-bold text-center">{props.question.description}</p>
            }
            <fieldset className="pure-form">
                <input type="phoneNumber" id="stacked-phoneNumber" placeholder="328 1233444"
                       className="pure-input-1 mb-2"
                       inputMode="tel"
                       onChange={ev => updateValue(ev)}
                       style={{fontSize: "200%", textAlign: "center", margin: "0 auto"}}/>
                {error &&
                    <span className="pure-form-message pure-form-danger text-center">{error}</span>
                }

            </fieldset>
        </div>
        <div className="mb-3 font-size-s text-center">Vista l'<a href={props.question.privacy_policy_link} target="_blank"><u>informativa privacy</u></a>,
            prendo atto che il trattamento dei miei dati è propedeutico all'erogazione
            dei servizi descritti
        </div>
        <button className="pure-button pure-button-primary pure-button-xxl pure-u-1"
                disabled={!phoneNumber}
                onClick={() => {
                    sendEvent('questionnaire-answer-phone')
                    props.answerCallback(new Answer(phoneNumber, 'phone'))
                }}>
            Invia ed accetta
        </button>


    </>
}
