import {Answer, ButtonQuestion, PhoneNumberQuestion, Question} from "./models";
import {TimAcquisitionQuestionnaire} from "./connectivityAcquisition";
import {sendMobileConversion} from "../bots/ConnectivityAcquisition";

let question1 = new ButtonQuestion("Cosa ti interessa maggiormente in un offerta mobile?",
    [new Answer("Giga"),
        new Answer("Minuti"),
        new Answer("SMS")])

let question2 = new ButtonQuestion("Cosa cerchi in un operatore telefonico?",
    [new Answer("Affidabilità"),
        new Answer("Velocità"),
        new Answer("Sicurezza")])

let question3 = new ButtonQuestion("Qual'è il tuo gestore telefonico attuale?",
    [new Answer("Vodafone"),
        new Answer("WindTre"),
        new Answer("Altro")])

let question4 = new ButtonQuestion("Sei soddisfatto del tuo gestore telefonico attuale?",
    [new Answer("No"),
        new Answer("Poco"),
        new Answer("Molto")])

let question5 = new ButtonQuestion("Qual'è l'utilizzo principale del tuo smartphone?",
    [new Answer("Lavoro"),
        new Answer("Social"),
        new Answer("Altro")])

let question6 = new ButtonQuestion("Quanti Giga hai con la tua offerta attuale?",
    [new Answer("Meno di 30"),
        new Answer("Tra 30 e 70"),
        new Answer("Più di 70")])

let question7 = new ButtonQuestion("Quanti minuti di chiamate utilizzi in un mese?",
    [new Answer("meno di 100"),
        new Answer("Tra i 100 e i 300"),
        new Answer("Più di 300")])

let question8 = new ButtonQuestion("Utilizzi il tuo smartphone come hotspot?",
    [new Answer("Mai"),
        new Answer("A volte"),
        new Answer("Spesso")])

let question9 = new ButtonQuestion("Quanto utilizzi giornalmente il tuo smartphone?",
    [new Answer("1-3 ore"),
        new Answer("4-6 ore"),
        new Answer("più di 6 ore")])

let question10 = new ButtonQuestion("Quanta importanza dai all'assistenza clienti del tuo operatore?",
    [new Answer("Poca"),
        new Answer("Normale"),
        new Answer("Molta")])

let redirectTo = new ButtonQuestion("Abbiamo trovato l'offerta migliore per le tue esigenze",
    [new Answer("Portami all'offerta", '', 'https://vidi-skok.club/trkclk/?pid=2701&cid=3249904'),])

let questionPhone = new PhoneNumberQuestion("Lasciaci il tuo numero di telefono",
    [], "Scrivimi il tuo numero di CELLULARE per ricevere tutte le informazioni 📲 👇🏼")

let tenQuestion = [question1, question2, question3, question4,
    question5, question6, question7, question8, question9, question10,
    questionPhone];

export function TimMobileAcquisitionQuestionnaire01() {
    let questions = tenQuestion as Question[]
    return <TimAcquisitionQuestionnaire questions={questions} conversion={sendMobileConversion}/>
}

let fiveQuestion = [question1, question2, question6, question7, question4, questionPhone];

export function TimMobileAcquisitionQuestionnaire02() {
    let questions = fiveQuestion as Question[]
    return <TimAcquisitionQuestionnaire questions={questions} conversion={sendMobileConversion}/>
}

let fiveQuestionRedirect = [question1, question2, question6, question7, question4, redirectTo];

export function TimMobileAcquisitionQuestionnaire04() {
    let questions = fiveQuestionRedirect as Question[]
    return <TimAcquisitionQuestionnaire questions={questions} conversion={sendMobileConversion}/>

}

