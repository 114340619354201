import {AVATAR, TriggerMetricDecorator} from "./BotsHelpers";

export function questionAsnwerFactory(name: string, question: string, answers: string[], nextStep: string): any[] {
    let answerId = `${name}-answer`;
    let options = answers.map((answer: string) => {
        return {
            label: answer,
            trigger: TriggerMetricDecorator(nextStep),
            value: answer
        }
    })
    return [
        {
            id: name,
            avatar: AVATAR,
            message: question,
            trigger: TriggerMetricDecorator(answerId),
        },
        {
            id: answerId,
            options: options
        },
    ];
}
