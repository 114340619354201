import React from "react";
import { Question } from "../models";
import {sendEvent} from "../../bots/BotsHelpers";

export function ButtonQuestionRender(props: { question: Question, answerCallback: any }) {
    const openInNewTab = (url:string) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (<>
            <p className="font-size-xl font-weight-bold text-center"
               style={{minHeight: '20vh'}}>{props.question.text}</p>
            <div className="push-bottom">
                {props.question.answers.map((answer) =>
                    <button className="pure-button pure-button-primary pure-button-xxl wrap-normal pure-u-1 mb-2"
                            key={answer.uuid}
                            onClick={() => {
                                if(answer.url) {
                                    openInNewTab(answer.url)
                                    sendEvent("lead_completed")
                                } else {
                                    props.answerCallback(answer)
                                }
                            }}>
                        {answer.text}
                    </button>
                )}
            </div>
        </>
    );
}
