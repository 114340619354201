import {Answer, ButtonQuestion, EmailQuestion, PhoneNumberQuestion, Question} from "./models";
import React, {useState} from "react";
import {sendEvent} from "../bots/BotsHelpers";
import {CryptoQuestionnaire, QuestionnaireProps} from "./cryptoAcquisition";

let question01 = new ButtonQuestion("Su cosa vorresti ricevere aiuto ?",
    [new Answer("Luce", 'question01'),
        new Answer("Gas", 'question01'),
        new Answer("Luce + Gas", 'question01'),
    ])

let question02 = new ButtonQuestion("Quale è il tuo fornitore attuale ?",
    [new Answer("A2A", 'question02'),
        new Answer("Edison", 'question02'),
        new Answer("Defurco", 'question02'),
        new Answer("Here Comm", 'question02'),
        new Answer("Estra", 'question02'),
        new Answer("Eni", 'question02'),
        new Answer("Enel", 'question02'),
        new Answer("Repower", 'question02'),
        new Answer("Axpo", 'question02'),
        new Answer("Altro", 'question02'),
    ])

let question03 = new ButtonQuestion("Sei soddisfatto del tuo fornitore attuale ?",
    [
        new Answer("No", 'question03'),
        new Answer("Poco", 'question03'),
        new Answer("Molto", 'question03'),
    ])

let question04 = new ButtonQuestion("Ritieni che la tua offerta sia la migliore ?",
    [
        new Answer("No", 'question04'),
        new Answer("Non saprei", 'question04'),
        new Answer("Potrebbe essere meglio", 'question04'),
        new Answer("Si", 'question04'),
    ])

let question05 = new ButtonQuestion("Vorresti essere seguito da un consulente di fiducia ?",
    [new Answer("Si", 'question05'),
        new Answer("Non saprei", 'question05'),
        new Answer("No", 'question05'),
    ])

let question06 = new ButtonQuestion("Cosa cerchi in una nuova offerta ?",
    [
        new Answer("Risparmio", 'question06'),
        new Answer("Affidabilità", 'question06'),
        new Answer("Assistenza", 'question06'),
        new Answer("Trasparenza", 'question06'),
    ])

let question07 = new ButtonQuestion("Come ti consideri riguardo l'uso dell'energia ?",
    [
        new Answer("Risparmiatore", 'question07'),
        new Answer("Attento", 'question07'),
        new Answer("Sprecone", 'question07'),
    ])

let question08 = new ButtonQuestion("Da quante persone è composto il tuo nucleo familiare ?",
    [
        new Answer("1", 'question08'),
        new Answer("2", 'question08'),
        new Answer("3", 'question08'),
        new Answer("4", 'question08'),
        new Answer("5 o più", 'question08'),
    ])

let question09 = new ButtonQuestion("Dove vivi ?",
    [
        new Answer("Città", 'question09'),
        new Answer("Campagna", 'question09'),
        new Answer("Altro", 'question09'),
    ])

let question10 = new ButtonQuestion("Quanto spendi in media al mese in bollette ?",
    [
        new Answer("Meno di 30€", 'question10'),
        new Answer("Tra 30€ e 60€", 'question10'),
        new Answer("Tra 60€ e 100€", 'question10'),
        new Answer("Più di 100€", 'question10'),
    ])

let question11 = new ButtonQuestion("Sei al corrente dei metodi di calcolo del conguaglio del tuo fornitore ?",
    [
        new Answer("Si", 'question11'),
        new Answer("No", 'question11'),
        new Answer("Non credo", 'question11'),
    ])
let question12 = new ButtonQuestion("Preferiresti un offerta a costo fisso o variabile ?",
    [
        new Answer("Fisso", 'question12'),
        new Answer("Variabile", 'question12'),
        new Answer("Non saprei", 'question12'),
    ])

let questionPhone = new PhoneNumberQuestion("Lasciaci il tuo numero di telefono",
    [], "https://www.iubenda.com/privacy-policy/503993", "Scrivimi il tuo numero di CELLULARE per ricevere tutte le informazioni 📲 👇🏼")

let questionEmail = new EmailQuestion("Lasciaci la tua email",
    [], "https://www.iubenda.com/privacy-policy/503993", "Lasciaci la tua email per ricevere informazioni sulle possibili offerte")

export function CustomEnergyQuestionnaire01() {
    let questions = [question01, question02, question03, question04, question05,
        question06, question07, question08, question09, question10, questionPhone, questionEmail] as Question[]
    const url = 'https://us-central1-feisty-gasket-100715.cloudfunctions.net/search-quoople-conversion'
    const completionCallback = async (context: any) => {
        try {
            let response = await fetch(url, {
                method: "POST",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(context)
            });
        } catch {
            sendEvent('lead_rejected')
        }
    }
    const logo = process.env.PUBLIC_URL + "/sos-bolletta-logo.png"
    const vcard = process.env.PUBLIC_URL + "/sos-bolletta.vcf"
    const thankyouPage = <>
        <div className="m-5">
            <p className="text-center font-size-l font-weight-bold">
                Salva il nostro contatto !
            </p>
            <p className={"text-center"}>
                <img src={logo} alt="" className={'img-fluid'}/>
            </p>
            <p className={"text-center"}>
                <a href={vcard} onClick={() => {sendEvent('download_contact')}}>
                    <button className="pure-button pure-button-primary pure-button-xxl wrap-normal pure-u-1 mb-2">
                        Salva contatto
                    </button>
                </a>
            </p>
            <p className="text-center">
                Un nostro consulente ti chiamerà nei prossimi giorni per aiutarti con la tua bolletta.
            </p>
        </div>
    </>
    return <>
        <CryptoQuestionnaire questions={questions}
                             completitionCallback={completionCallback}
                             thankyouPage={thankyouPage}
                             debug={false}
        />
    </>
}

