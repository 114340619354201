import React from "react";
import {uuidv4} from "./connectivityAcquisition";
import {ButtonQuestionRender} from "./questions/buttonQuestionRender";
import {PhoneNumberFormRender} from "./questions/phoneNumberFormRender";
import {EmailFormRender} from "./questions/emailFormRender";

export class Question {
    text: string;
    answers: Answer[];
    renderer: any;
    description: string | undefined;

    constructor(questionText: string, answers: Answer[], description?: string) {
        this.text = questionText
        this.answers = answers
        this.description = description
    }

    render(answerCallback: any) {
        return React.createElement(this.renderer,
            {question: this, answerCallback: answerCallback})
    }
}

export class PersonalInformationQuestion extends Question {
    privacy_policy_link: string;
    constructor(questionText: string, answers: Answer[], privacy_policy_link: string, description?: string) {
        super(questionText, answers, description)
        this.privacy_policy_link = privacy_policy_link
    }
}

export class PhoneNumberQuestion extends PersonalInformationQuestion {
    renderer = PhoneNumberFormRender
}

export class EmailQuestion extends PersonalInformationQuestion {
    renderer = EmailFormRender
}

export class ButtonQuestion extends Question {
    renderer = ButtonQuestionRender
}


export class Answer {
    private readonly field: string | undefined;

    updateContext(context: {}) {
        if(this.field != undefined){
            // @ts-ignore
            context[this.field] = this.text
        }
    }
    url: string | undefined;
    text: string;
    uuid: any;

    constructor(answer: string, field?: string, url?: string) {
        this.text = answer
        this.uuid = uuidv4()
        this.field = field
        this.url = url
    }
}

