import React, {useState} from "react";
import {Answer, PersonalInformationQuestion, Question} from "../models";
import {sendEvent} from "../../bots/BotsHelpers";

function emailValidator(value: string): string {
    return ""
}

export function EmailFormRender(props: { question: PersonalInformationQuestion, answerCallback: any }) {
    const [email, setEmail] = useState("")
    const [error, setError] = useState<string>("")

    function updateValue(ev: React.ChangeEvent<HTMLInputElement>) {
        let validationErrors = emailValidator(ev.target.value)
        if (validationErrors == '') {
            setError("")
            setEmail(ev.target.value)
        } else {
            setError(validationErrors)
            setEmail("")
        }
    }

    return <>
        <div style={{minHeight: '40vh'}}>
            <p className="font-size-xl font-weight-bold text-center">{props.question.text}</p>
            {props.question.description &&
                <p className="font-size-l font-weight-bold text-center">{props.question.description}</p>
            }
            <fieldset className="pure-form">
                <input type="email" id="stacked-email" placeholder="indirizzo@example.com"
                       className="pure-input-1 mb-2"
                       inputMode="email"
                       onChange={ev => updateValue(ev)}
                       style={{fontSize: "200%", textAlign: "center", margin: "0 auto"}}/>
                {error &&
                    <span className="pure-form-message pure-form-danger text-center">{error}</span>
                }

            </fieldset>
        </div>
        <div className="mb-3 font-size-s text-center">Vista l'<a href={props.question.privacy_policy_link} target="_blank"><u>informativa privacy</u></a>,
            prendo atto che il trattamento dei miei dati è propedeutico all'erogazione
            dei servizi descritti
        </div>
        <button className="pure-button pure-button-primary pure-button-xxl pure-u-1"
                disabled={!email}
                onClick={() => {
                    sendEvent('questionnaire-answer-email')
                    props.answerCallback(new Answer(email, 'email'))
                }}>
            Invia ed accetta
        </button>


    </>
}
