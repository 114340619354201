import {Md5} from "ts-md5";
const SIGNATURE_SECRET = "test";
const SIGNATURE_EXPIRACY_SECONDS = 60 * 5;

export const verifySignedTimestamp = function (value: string | null) {
    if (value) {
        const currentTimestamp = Math.floor(Date.now() / 1000)
        let signatureSeparator = ":";
        const [timestampString, signature] = value.split(signatureSeparator)
        const expectedSignature = Md5.hashStr(`${timestampString}:${SIGNATURE_SECRET}`)
        console.log('expected signature', expectedSignature)
        const numericTimestamp = parseFloat(timestampString)
        console.log(currentTimestamp - numericTimestamp, '>=', SIGNATURE_EXPIRACY_SECONDS)
        return {
            timestamp: numericTimestamp,
            isValid: signature === expectedSignature,
            isExpired: currentTimestamp - numericTimestamp >= SIGNATURE_EXPIRACY_SECONDS,
        }
    }
}

export const generateSignedTimestamp = function () {
    const timestamp = Math.floor(Date.now() / 1000).toString()
    return `${timestamp}:${Md5.hashStr(`${timestamp}:${SIGNATURE_SECRET}`)}`
}