import {AVATAR, TriggerMetricDecorator} from "../BotsHelpers";
import React from "react";
import {TIMAcquisitionBot} from "../ConnectivityAcquisition";
import {PhoneNumberInput} from "../PhoneNumberInput";

const start = 'start'
const welcomeMessage = 'welcomeMessage00-v01'
const welcomeMessage01 = 'welcomeMessage01';
const welcomeMessageAnswer = "welcomeMessageAnswer";
const moreInfo00 = "moreInfo00"
const moreInfo01 = "moreInfo01"
const moreInfo02 = "moreInfo02"
const moreInfo03 = "moreInfo03"
const moreInfo04 = "moreInfo04"
const askForPrivacyPolicy = "askForPrivacyPolicy"
const answerForPrivacyPolicy = "answerForPrivacyPolicy";
const startAcquisitionForm = "startAcquisitionForm"
const thanksAndGoodbye = "thanksAndGoodbye";
const notInterestedReceovery00 = "notInterestedRecovery";
const notInterestedReceoveryAnswer = "notInterestedReceoveryAnswer";
const notInterestedReceovery01 = "notInterestedReceovery01";
const acquisitionForm03Answer = "acquisitionForm03Answer";
export const thanksAndSeeYouSoon = "thanksAndSeeYouSoon";
const usefullQuestion00 = 'usefullQuestion00';
const usefullQuestion01 = 'usefullQuestion01';
const usefullQuestion02 = 'usefullQuestion02';

export const TIMAcquisitionBot01 = () => {
    const steps = [
        {
            id: start,
            avatar: AVATAR,
            message: `👏 Ciao, benvenuto/a!`,
            trigger: TriggerMetricDecorator(welcomeMessage),
        },
        {
            id: welcomeMessage,
            avatar: AVATAR,
            message: `Abbiamo una fantastica offerta per te`,
            trigger: TriggerMetricDecorator(welcomeMessage01),
        },
        // ...questionAsnwerFactory(usefullQuestion00, "Quanto e' veloce la tua connessione?", ["Lenta", "Normale", "Veloce"], usefullQuestion01),
        // ...questionAsnwerFactory(usefullQuestion01, "Dove vivi?", ["In città", "In campagna"], usefullQuestion02),
        // ...questionAsnwerFactory(usefullQuestion02, "Quanto quanto spendi per la tua connessione?", ["Meno di 10€", "Meno di 30€", "Piú di 30€", "Non so"], moreInfo00),
        {
            id: welcomeMessage01,
            avatar: AVATAR,
            message: `Con PREMIUM FIBRA navighi da CASA con LA RETE FISSA PIU' VELOCE D’ITALIA`,
            trigger: TriggerMetricDecorator(welcomeMessageAnswer),
        },
        {
            id: welcomeMessageAnswer,
            options: [
                {label: `Assolutamente sì 👍`, trigger: TriggerMetricDecorator(moreInfo00), value: moreInfo00},
                {
                    label: `No, non mi interessa`,
                    trigger: TriggerMetricDecorator(notInterestedReceovery00),
                    value: notInterestedReceovery00
                }]
        },
        {
            id: notInterestedReceovery00,
            avatar: AVATAR,
            message: `Sei davvero sicuro? con la nostra offerta potrai navigare fino a 1 gbps senza limiti`,
            trigger: TriggerMetricDecorator(notInterestedReceovery01),
        },
        {
            id: notInterestedReceovery01,
            avatar: AVATAR,
            message: `Allora, sei sicuro di non voler proseguire?`,
            trigger: TriggerMetricDecorator(notInterestedReceoveryAnswer),
        },
        {
            id: notInterestedReceoveryAnswer,
            options: [
                {label: `Assolutamente si 👍`, trigger: TriggerMetricDecorator(moreInfo00), value: moreInfo00},
                {
                    label: `No, non mi interessa`,
                    trigger: TriggerMetricDecorator(thanksAndGoodbye),
                    value: thanksAndGoodbye
                },
            ]
        },
        {
            id: moreInfo00,
            avatar: AVATAR,
            message: `Con l'offerta PREMIUM FIBRA avrai a tua disposizione:`,
            trigger: TriggerMetricDecorator(moreInfo01),
        },
        {
            id: moreInfo01,
            avatar: AVATAR,
            message: `✅  VELOCITÁ FINO A 1 GIGA`,
            trigger: TriggerMetricDecorator(moreInfo02),
        },
        {
            id: moreInfo02,
            avatar: AVATAR,
            message: `✅  Chiamate illimitate`,
            trigger: TriggerMetricDecorator(moreInfo03),
        },
        {
            id: moreInfo03,
            avatar: AVATAR,
            message: `✅  Il modem TIM Hub+`,
            trigger: TriggerMetricDecorator(moreInfo04),
        },
        {
            id: moreInfo04,
            avatar: AVATAR,
            asMessage: true,
            component: (<div>Tutto questo a <strong>soli 24,90 €</strong> al mese</div>),
            trigger: TriggerMetricDecorator(startAcquisitionForm),
        },
        {
            id: askForPrivacyPolicy,
            avatar: AVATAR,
            asMessage: true,
            component: (
                <div>Vista l'<a href="https://www.tim.it/web-privacy-policy-di"><u>informativa privacy</u></a>, prendo
                    atto che
                    il trattamento dei miei dati è propedeutico all'erogazione dei servizi descritti</div>
            ),
            trigger: TriggerMetricDecorator(answerForPrivacyPolicy),
        },
        {
            id: answerForPrivacyPolicy,
            options: [
                {
                    label: `Si ho letto e accetto i termini 👍`,
                    trigger: TriggerMetricDecorator(thanksAndSeeYouSoon),
                    value: thanksAndSeeYouSoon
                },
                {
                    label: `No, non mi interessa`,
                    trigger: TriggerMetricDecorator(thanksAndGoodbye),
                    value: thanksAndGoodbye
                },
            ]
        },
        {
            id: thanksAndGoodbye,
            avatar: AVATAR,
            message: `È stato un piacere, alla prossima volta`,
            end: true
        },
        {
            id: startAcquisitionForm,
            avatar: AVATAR,
            message: `Se sei interessato, lascia un contatto per essere richiamato dai nostri operatori`,
            trigger: TriggerMetricDecorator(acquisitionForm03Answer),
        },
        {
            id: acquisitionForm03Answer,
            component: <PhoneNumberInput/>,
            waitAction: true,
            trigger: TriggerMetricDecorator(askForPrivacyPolicy),
        },
        {
            id: thanksAndSeeYouSoon,
            avatar: AVATAR,
            message: "Grazie per aver inviato i tuoi dati! Un nostro operatore ti ricontatterà a breve",
            end: true,
        },

    ]
    return <TIMAcquisitionBot steps={steps}/>
}
