import React from "react";
import {verifySignedTimestamp} from "../signatures";
import {phoneNumberValidator} from "../validators";
import {ThemeProvider} from "styled-components";
import ChatBot from "react-simple-chatbot";
import {Link} from "react-router-dom";
import {BackArrow} from "../icons";
import {AVATAR, ClickTrughtLanding, sendEvent, TriggerMetricDecorator} from "./BotsHelpers";


const start = 'start'
const welcomeMessage = 'welcomeMessage'
const welcomeMessageAnswer = "welcomeMessageAnswer";
const moreInfo00 = "moreInfo00"
const moreInfo01 = "moreInfo01"
const moreInfo02 = "moreInfo02"
const moreInfo03 = "moreInfo03"
const moreInfo04 = "moreInfo04"
const askForPrivacyPolicy = "askForPrivacyPolicy"
const answerForPrivacyPolicy = "answerForPrivacyPolicy";
const startAcquisitionForm = "startAcquisitionForm"
const thanksAndGoodbye = "thanksAndGoodbye";
const acquisitionForm01 = "acquisitionForm01";
const acquisitionForm02 = "acquisitionForm02";
const acquisitionForm03 = "acquisitionForm03";
const notInterestedReceovery00 = "notInterestedRecovery";
const notInterestedReceoveryAnswer = "notInterestedReceoveryAnswer";
const notInterestedReceovery01 = "notInterestedReceovery01";
const acquisitionForm01Answer = "acquisitionForm01Answer";
const acquisitionForm02Answer = "acquisitionForm02Answer";
const acquisitionForm03Answer = "acquisitionForm03Answer";
const thanksAndSeeYouSoon = "thanksAndSeeYouSoon";
const acquisitionFormReview = "acquisitionFormReview";

// pallette: https://coolors.co/0d3028-4f1321-db728b-17755f-54c4aa
const chatbotTheme = {
    background: '#f5f8fb',
    fontFamily: 'Helvetica Neue',
    headerBgColor: '#4f1321',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#17755F',
    botFontColor: '#fff',
    userBubbleColor: '#54C4AA',
    userFontColor: '#000',
};

function AcquisitionFormReview(props: any) {
    const firstName = props.steps.acquisitionForm01Answer?.value
    const lastName = props.steps.acquisitionForm02Answer?.value
    const phoneNumber = props.steps.acquisitionForm03Answer?.value
    return (
        <div>
            <div>{firstName}</div>
            <div>{lastName}</div>
            <div>{phoneNumber}</div>
        </div>
    );
}

function endProcessCallback(props: any) {
    const firstName = props.steps.acquisitionForm01Answer?.value
    const lastName = props.steps.acquisitionForm02Answer?.value
    const phoneNumber = props.steps.acquisitionForm03Answer?.value
    const ppAccepted = props.steps.answerForPrivacyPolicy?.value === "thanksAndSeeYouSoon"
    if (firstName && lastName && phoneNumber && ppAccepted) {
        sendEvent("formFilled")
        const data = JSON.stringify({
            "nome": firstName,
            "cognome": lastName,
            "numeroTelefono": `0039${phoneNumber}`,
            "descrizioneOfferta": "adobe_adv",
            "urlHttp": "adobe_adv"
        })
        const uuid = Math.random().toString()
        const form = document.createElement('form')
        form.method = 'post'
        form.action = 'https://www.enel.it/bin/enel-it/leadconsensati'
        form.style.display = "none"
        form.innerHTML = `
        <input id="input-${uuid}" type="text" name="data">
        <input id="submit-${uuid}" type="submit" value="Submit">
    `
        document.body.append(form)
        const input = document.getElementById(`input-${uuid}`) as any
        if (input) {
            input.value = data
            document.getElementById(`submit-${uuid}`)?.click()
        }
    }
}

export const EnelAcquisitionBot: React.FC = (props) => {
    const params = new URLSearchParams(window.location.search)
    const value = params.get('_s')
    const signedTimestamp = verifySignedTimestamp(value)
    const avatar = AVATAR

    const steps = [
        {
            id: start,
            avatar,
            message: `👏 Ciao, benvenuto/a!`,
            trigger: TriggerMetricDecorator(welcomeMessage),
        },
        {
            id: welcomeMessage,
            avatar,
            message: `📣 Ti piacerebbe ricevere sconti esclusivi sul prezzo della componente Energia e Gas?`,
            trigger: TriggerMetricDecorator(welcomeMessageAnswer),
        },
        {
            id: welcomeMessageAnswer,
            options: [
                {label: `Assolutamente sì 👍`, trigger: TriggerMetricDecorator(moreInfo00), value: moreInfo00},
                {label: `No, non mi interessa`, trigger: TriggerMetricDecorator(notInterestedReceovery00), value: notInterestedReceovery00},
            ]
        },
        {
            id: notInterestedReceovery00,
            avatar,
            message: `Sei davvero sicuro? Con le offerte di Enel Energia hai accesso ad una fonte 100% rinnovabile e i primi 3 mesi sono gratuiti 🎁`,
            trigger: TriggerMetricDecorator(notInterestedReceovery01),
        },
        {
            id: notInterestedReceovery01,
            avatar,
            message: `Allora, sei sicuro di non voler proseguire?`,
            trigger: TriggerMetricDecorator(notInterestedReceoveryAnswer),
        },
        {
            id: notInterestedReceoveryAnswer,
            options: [
                {label: `Spiegami l’offerta 👍`, trigger: TriggerMetricDecorator(moreInfo00), value: moreInfo00},
                {label: `No, non mi interessa`, trigger: TriggerMetricDecorator(thanksAndGoodbye), value: thanksAndGoodbye},
            ]
        },
        {
            id: moreInfo00,
            avatar,
            message: `🎁  Con Scegli Tu Special 3 hai a disposizione:`,
            trigger: TriggerMetricDecorator(moreInfo01),
        },
        {
            id: moreInfo01,
            avatar,
            message: `✅  100% energia rinnovabile`,
            trigger: TriggerMetricDecorator(moreInfo02),
        },
        {
            id: moreInfo02,
            avatar,
            message: `✅  Primi 3 mesi gratuiti per la componente energia`,
            trigger: TriggerMetricDecorator(moreInfo03),
        },
        {
            id: moreInfo03,
            avatar,
            message: `✅  Assistenza dedicata per l’attivazione dell’offerta`,
            trigger: TriggerMetricDecorator(startAcquisitionForm),
        },
        {
            id: askForPrivacyPolicy,
            avatar,
            asMessage: true,
            component: (
                <div>Vista <a href="https://www.enel.it/it/contatti/lead-adv">l'informativa privacy</a>, prendo atto che il trattamento dei miei dati è propedeutico all'erogazione dei servizi descritti</div>
            ),
            trigger: TriggerMetricDecorator(answerForPrivacyPolicy),
        },
        {
            id: answerForPrivacyPolicy,
            options: [
                {
                    label: `Si ho letto e accetto i termini 👍`,
                    trigger: TriggerMetricDecorator(thanksAndSeeYouSoon),
                    value: thanksAndSeeYouSoon
                },
                {label: `No, non mi interessa`, trigger: TriggerMetricDecorator(thanksAndGoodbye), value: thanksAndGoodbye},
            ]
        },
        {
            id: thanksAndGoodbye,
            avatar,
            message: `È stato un piacere, alla prossima volta`,
            end: true
        },
        {
            id: startAcquisitionForm,
            avatar,
            message: `Lasciaci i tuoi dati per essere ricontattato in poco tempo da un nostro consulente esperto. ✅ Ti ci vogliono solo due minuti!`,
            trigger: TriggerMetricDecorator(acquisitionForm01),
        },
        {
            id: acquisitionForm01,
            avatar,
            message: `Ci serve il tuo nome`,
            trigger: TriggerMetricDecorator(acquisitionForm01Answer),
        },
        {
            id: acquisitionForm01Answer,
            user: true,
            trigger: TriggerMetricDecorator(acquisitionForm02),
        },

        {
            id: acquisitionForm02,
            avatar,
            message: `Il tuo cognome`,
            trigger: TriggerMetricDecorator(acquisitionForm02Answer),
        },
        {
            id: acquisitionForm02Answer,
            user: true,
            trigger: TriggerMetricDecorator(acquisitionForm03),
        },
        {
            id: acquisitionForm03,
            avatar,
            message: `Il tuo numero di telefono`,
            trigger: TriggerMetricDecorator(acquisitionForm03Answer),
        },
        {
            id: acquisitionForm03Answer,
            user: true,
            validator: phoneNumberValidator,
            placeholder: `Telefono: 333 3333 333`,
            trigger: TriggerMetricDecorator(acquisitionFormReview),
        },
        {
            id: acquisitionFormReview,
            avatar,
            component: <AcquisitionFormReview/>,
            asMessage: true,
            trigger: TriggerMetricDecorator(askForPrivacyPolicy),
        },
        {
            id: thanksAndSeeYouSoon,
            avatar,
            message: "Grazie per aver inviato i tuoi dati!",
            end: true,
        },

    ]
    let DEBUG = false;
    if (DEBUG) {
        steps.filter((s) => s.id === welcomeMessageAnswer).map((s) => {
            if (s && s.options) {
                s.options.push({label: `DEBUG: open form`, trigger: TriggerMetricDecorator(startAcquisitionForm), value: startAcquisitionForm})
            }
            return s
        })
    }
    const showChatbot = (signedTimestamp && signedTimestamp?.isValid && !signedTimestamp?.isExpired)
    return (
        <>
            {showChatbot &&
            <ThemeProvider theme={chatbotTheme}>

                <ChatBot headerTitle={"Andre Ai di Enel Energia"}
                         steps={steps}
                         width={"100%"}
                         handleEnd={endProcessCallback}/>
                <Link className="floatingBackButton" to="/">
                    <BackArrow fill="white"></BackArrow>
                </Link>
            </ThemeProvider>
            }
            {!showChatbot &&
            <ClickTrughtLanding></ClickTrughtLanding>
            }
        </>
    )
}
