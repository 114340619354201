import {AVATAR, TriggerMetricDecorator} from "../BotsHelpers";
import React from "react";
import {TIMAcquisitionBot} from "../ConnectivityAcquisition";
import {PhoneNumberInput} from "../PhoneNumberInput";
import {questionAsnwerFactory} from "../QuestionAsnwerFactory";

const start = 'start'
const welcomeMessage = 'welcomeMessage00-v01'
const welcomeMessage01 = 'welcomeMessage01';
const welcomeMessageAnswer = "welcomeMessageAnswer";
const moreInfo00 = "moreInfo00"
const moreInfo01 = "moreInfo01"
const moreInfo02 = "moreInfo02"
const moreInfo03 = "moreInfo03"
const moreInfo04 = "moreInfo04"
const askForPrivacyPolicy = "askForPrivacyPolicy"
const answerForPrivacyPolicy = "answerForPrivacyPolicy";
const startAcquisitionForm = "startAcquisitionForm"
const thanksAndGoodbye = "thanksAndGoodbye";
const notInterestedReceovery00 = "notInterestedRecovery";
const notInterestedReceoveryAnswer = "notInterestedReceoveryAnswer";
const notInterestedReceovery01 = "notInterestedReceovery01";
const acquisitionForm03Answer = "acquisitionForm03Answer";
export const thanksAndSeeYouSoon = "thanksAndSeeYouSoon";
const usefullQuestion00 = 'usefullQuestion00';
const usefullQuestion01 = 'usefullQuestion01';
const usefullQuestion02 = 'usefullQuestion02';

export const VerisureAcquisitionBot01 = () => {
    let welcomeMessage02 = 'welcomeMessage02';
    const steps = [
        {
            id: start,
            avatar: AVATAR,
            message: `👏 Ciao, benvenuto/a!`,
            trigger: TriggerMetricDecorator(welcomeMessage),
        },
        {
            id: welcomeMessage,
            avatar: AVATAR,
            message: `Abbiamo una fantastica offerta per te`,
            trigger: TriggerMetricDecorator(welcomeMessage01),
        },
        // ...questionAsnwerFactory(usefullQuestion00, "Quanto e' veloce la tua connessione?", ["Lenta", "Normale", "Veloce"], usefullQuestion01),
        // ...questionAsnwerFactory(usefullQuestion01, "Dove vivi?", ["In città", "In campagna"], usefullQuestion02),
        // ...questionAsnwerFactory(usefullQuestion02, "Quanto quanto spendi per la tua connessione?", ["Meno di 10€", "Meno di 30€", "Piú di 30€", "Non so"], moreInfo00),
        {
            id: welcomeMessage01,
            avatar: AVATAR,
            message: `🚨Installa l'allarme per la tua casa e previeni i pericoli 🙌 👉 installazione professionale a partire da €299 👉 approfitta del 50% di sconto!`,
            trigger: TriggerMetricDecorator(welcomeMessage02),
        },
        {
            id: welcomeMessage02,
            avatar: AVATAR,
            message: `Rispondi a un breve questionario e ottieni subito un PREVENTIVO GRATUITO 👇`,
            trigger: TriggerMetricDecorator(welcomeMessageAnswer),
        },
        {
            id: welcomeMessageAnswer,
            options: [
                {label: `Assolutamente sì 👍`,
                    trigger: TriggerMetricDecorator(moreInfo00), value: moreInfo00},
                {
                    label: `No, non mi interessa`,
                    trigger: TriggerMetricDecorator(notInterestedReceovery00),
                    value: notInterestedReceovery00
                }]
        },
        ...questionAsnwerFactory(moreInfo00, "L'allarme è per la casa o business?",
                ["Casa", "Business"], moreInfo01),
        ...questionAsnwerFactory(moreInfo01, "In che tipo di casa risiedi?",
                ["Appartamento", "Villa"], moreInfo02),
        ...questionAsnwerFactory(moreInfo02, "Dove si trova la casa?",
                ["Nel nucleo urbano", "Fuori il nucleo urbano"], moreInfo03),
        ...questionAsnwerFactory(moreInfo03, "Com'è l'accesso principale della casa?",
                ["Ingresso indipendente", "Ingresso condiviso", "Ingresso condiviso, con portiere"], acquisitionForm03Answer),
        {
            id: notInterestedReceovery00,
            avatar: AVATAR,
            message: `Sei davvero sicuro? con la nostra offerta potrai navigare fino a 1 gbps senza limiti`,
            trigger: TriggerMetricDecorator(notInterestedReceovery01),
        },
        {
            id: notInterestedReceovery01,
            avatar: AVATAR,
            message: `Allora, sei sicuro di non voler proseguire?`,
            trigger: TriggerMetricDecorator(notInterestedReceoveryAnswer),
        },
        {
            id: notInterestedReceoveryAnswer,
            options: [
                {label: `Assolutamente si 👍`, trigger: TriggerMetricDecorator(moreInfo00), value: moreInfo00},
                {
                    label: `No, non mi interessa`,
                    trigger: TriggerMetricDecorator(thanksAndGoodbye),
                    value: thanksAndGoodbye
                },
            ]
        },
        {
            id: askForPrivacyPolicy,
            avatar: AVATAR,
            asMessage: true,
            component: (
                <div>Vista l'<a href="https://www.verisure.it/sites/it/files/2021-10/5.%20Informativa%20privacy%20Clienti%20finalit%C3%A0%20marketing.pdf">
                    <u>informativa privacy</u></a>, prendo  atto che il trattamento dei miei dati è propedeutico
                    all'erogazione dei servizi descritti
                </div>
            ),
            trigger: TriggerMetricDecorator(answerForPrivacyPolicy),
        },
        {
            id: answerForPrivacyPolicy,
            options: [
                {
                    label: `Si ho letto e accetto i termini 👍`,
                    trigger: TriggerMetricDecorator(thanksAndSeeYouSoon),
                    value: thanksAndSeeYouSoon
                },
                {
                    label: `No, non mi interessa`,
                    trigger: TriggerMetricDecorator(thanksAndGoodbye),
                    value: thanksAndGoodbye
                },
            ]
        },
        {
            id: thanksAndGoodbye,
            avatar: AVATAR,
            message: `È stato un piacere, alla prossima volta`,
            end: true
        },
        {
            id: startAcquisitionForm,
            avatar: AVATAR,
            message: `Se sei interessato, lascia un contatto per essere richiamato dai nostri operatori`,
            trigger: TriggerMetricDecorator(acquisitionForm03Answer),
        },
        {
            id: acquisitionForm03Answer,
            component: <PhoneNumberInput/>,
            waitAction: true,
            trigger: TriggerMetricDecorator(askForPrivacyPolicy),
        },
        {
            id: thanksAndSeeYouSoon,
            avatar: AVATAR,
            message: "Un nostro consulente ti contatterà per completare il tuo Studio di Sicurezza gratuito e proporti la soluzione più adatta alle tue esigenze",
            end: true,
        },

    ]
    return <TIMAcquisitionBot steps={steps} verifySignature={false} title={"Security assistant"}/>
}
