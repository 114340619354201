import React, {useState} from "react";
import {phoneNumberValidator} from "../validators";
import {Button, Col, Input, Row} from "reactstrap";

export function PhoneNumberInput(props: any) {
    let [disabled, updateDisabled] = useState(true)
    let [submitted, updateSubmitted] = useState(false)

    function updateValue(ev: React.ChangeEvent<HTMLInputElement>) {
        const isValid = phoneNumberValidator(ev.target.value)
        if (isValid === true) {
            props.step.value = ev.target.value
            updateDisabled(false)
        }
    }

    function submitInput() {
        if (!disabled) {
            updateSubmitted(true)
            props.triggerNextStep(props.step.trigger)
        }
    }

    if (submitted) {
        return props.step.value
    } else {
        return (
            <Row>
                <Col xs={10}>
                    <Input autoFocus type="number" name="phone"
                           onChange={ev => updateValue(ev)}
                           placeholder="Telefono: 333 3333 333"
                           onKeyDown={ev => ev.key === 'Enter' && submitInput()}/>
                </Col>
                <Col xs={2}>
                    <Button disabled={disabled && !submitted} color="primary" onClick={ev => submitInput()}>ok</Button>
                </Col>
            </Row>
        );
    }
}
