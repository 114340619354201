import {Answer, ButtonQuestion, Question} from "./models";
import {useState} from "react";
import {sendEvent} from "../bots/BotsHelpers";

export interface QuestionnaireProps {
    questions: Question[]
    completitionCallback?: any
    thankyouPage?: any
    debug?: boolean
}

let question01 = new ButtonQuestion("Chi o cosa è noto come Satoshi Nakamoto?",
    [new Answer("Un gruppo di informatici della West Coast che ha lanciato Bitcoin "),
        new Answer("Nessuno lo sa con essattezza"),
        new Answer("Lo pseudonimo dell'individuo che ha inventato Bitcoin")])

let question02 = new ButtonQuestion("Di cosa è fatto il Bitcoin?",
    [new Answer("Silicio"),
        new Answer("Pixel"),
        new Answer("Firme digitali")])

let question03 = new ButtonQuestion("Cos’è la Blockchain?",
    [new Answer("Il registro che certifica tutte le transazioni di bitcoin"),
        new Answer("La società che possiede i diritti sul Bitcoin"),
        new Answer("Il software sul quale gira il sistema di Bitcoin")])

let question04 = new ButtonQuestion("Quale paese ha adottato il bitcoin come sistema di pagamento?",
    [new Answer("Corea del Sud"),
        new Answer("Malta"),
        new Answer("Giappone")])

let question05 = new ButtonQuestion("Nello slang delle criptovalute cosa si intende per hard fork?",
    [new Answer("Un'oscillazione improvvisa, al rialzo o al ribasso, superiore al 10% nelle 24 ore"),
        new Answer("Una potenziale scissione della Blockchain di Bitcoin"),
        new Answer("Il sistema di anomizzazione del Bitcoin")])

let question06 = new ButtonQuestion("Che cosa si intende per Ico?",
    [new Answer("L'offerta pubblica iniziale di azioni di società attiva sulle Criptovalute"),
        new Answer("L'emissione di una nuova Criptovaluta o di un Token per finanziare iniziative nel mondo delle Criptovalute"),
        new Answer("L'emissione di una nuova Criptovaluta che punta a sostituire il Bitcoin")])

let question07 = new ButtonQuestion("Cosa sono le stablecoin?",
    [new Answer("Criptovalute che puntano a trasformarsi in valute fiat nel prossimo decennio"),
        new Answer("Criptovalute che hanno registrato oscillazioni contenute entro una fascia del 5% al rialzo o al ribasso negli ultimi sei mesi"),
        new Answer("Criptovalute agganciate a una valuta tradizionale o a un asset fisico")])

let question08 = new ButtonQuestion("Un paese europeo ha adottato proprio questa settimana una legislazione che ha regolamentato la blockchain e le Ico. Quale?",
    [new Answer("Lussemburgo"),
        new Answer("San Marino"),
        new Answer("Malta")])

let question09 = new ButtonQuestion("Cosa succederà nel 2140, se tutto procederà come previsto?",
    [new Answer("Sarà creato l'ultimo Bitcoin"),
        new Answer("La Blockchain di Bitcoin smetterà di esistere"),
        new Answer("I Bitcoin esistenti dovranno essere duplicati se on saranno più disponibili")])

let redirectTo = new ButtonQuestion("Ottimo, sei davero Crypto-Master, Adesso è arrivato il momento di metterti in gioco!",
    [new Answer("Portami all'offerta", '', 'https://ziedesprong.live/trkclk/?pid=1887&cid=3265211'),])

export function CryptoQuestionnaire01() {
    let questions = [question01, question02, question03, question04, question05,
        question06, question07, question08, question09, redirectTo]
    return <CryptoQuestionnaire questions={questions}/>
}


export const CryptoQuestionnaire = ({questions, completitionCallback, thankyouPage, debug}: QuestionnaireProps) => {
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [context, setContext] = useState<{phone: string|null}>({phone: null})
    let leadSent = false
    if(!thankyouPage) {
        thankyouPage = <>
            <div className="m-5">
                <p className="text-center font-size-l font-weight-bold">
                    Grazie per averci lasciato le tue informazioni.
                </p>
                <p className="text-center">
                    Stiamo elaborando la tua richiesta per ricontattarti il prima possibile e illustrarti la
                    nostra offerta
                </p>
            </div>
        </>
    }

    let goToNext = async (answer: Answer) => {
        sendEvent(`questionnaire-answer-${currentQuestion}`)
        answer.updateContext(context)
        setContext(context)
        let newQuestion = currentQuestion + 1
        setCurrentQuestion(newQuestion)
        if (!questions[newQuestion] && context.phone !== null && !leadSent) {
            if(completitionCallback && context.phone != '3333333333') {
                completitionCallback(context)
            }
            leadSent = true
            sendEvent("lead_completed")
        }

    }
    const logo = process.env.PUBLIC_URL + "/sos-bolletta-logo-small.png"
    return (<>
            <div className="pure-g header mb-2" >
                <div className="pure-u-1-5 font-size-l"><img src={logo} className={"img-fluid"} width={"50px"}/></div>
                <div className="pure-u-4-5 font-size-l">SOS Bolletta</div>
            </div>
            { debug &&
            <div className="pure-g">
                <div className="pure-u-1-5"></div>
                <div className="pure-u-1-3">{ JSON.stringify(context) }</div>
                <div className="pure-u-1-3"></div>
            </div>
            }
            <div className="pure-g">
                <div className="pure-u-1-5 pure-hidden-md"><p></p></div>
                <div className="pure-u-1 pure-u-md-3-5">
                    {questions[currentQuestion] &&
                        questions[currentQuestion].render(goToNext)
                    }
                    {!questions[currentQuestion] &&
                        thankyouPage
                    }
                </div>
                <div className="pure-u-1-5 pure-hidden-md"><p></p></div>
            </div>
        </>
    )
}
