import {CryptoQuestionnaire} from "./cryptoAcquisition";
import {Answer, ButtonQuestion} from "./models";


let question01 = new ButtonQuestion("Hai una macchina Diesel, Benzina o Ibrida?",
    [
        new Answer("Diesel", 'question01'),
        new Answer("Benzina", 'question01'),
        new Answer("Ibrida ", 'question01'),
        new Answer("CPL/Metano ", 'question01'),
        new Answer("Elettrica ", 'question01'),
    ])
let question02 = new ButtonQuestion("Che mezzo utilizzi?",
    [
        new Answer("Ciclomotore", 'phone'),
        new Answer("Autovettura", 'phone'),
        new Answer("Camion", 'phone'),
    ])
export function CustomEniAcquisition01() {
    const questions = [question01, question02];

    const completitionCallback = async (context: any) => {
        const queryParameters = new URLSearchParams(window.location.search)
        const custom1 = queryParameters.get("custom1") || ""
        const custom2 = queryParameters.get("custom2") || ""
        const custom3 = queryParameters.get("custom3") || ""
        const custom4 = queryParameters.get("custom4") || ""
        const cid = queryParameters.get("cid") || ""
        const gaid = queryParameters.get("gaid") || ""
        const idfa = queryParameters.get("idfa") || ""
        const nofw = queryParameters.get("nofw") || ""
        const fw1 = queryParameters.get("fw1") || ""
        const fw2 = queryParameters.get("fw2") || ""
        const destination = new URL(`https://ebd9415b40.smapp.work/trkclk/`)
        destination.searchParams.set('pid', '1519')
        destination.searchParams.set('cid', cid)
        destination.searchParams.set('custom1', custom1)
        destination.searchParams.set('custom2', custom2)
        destination.searchParams.set('custom3', custom3)
        destination.searchParams.set('custom4', custom4)
        destination.searchParams.set('gaid', gaid)
        destination.searchParams.set('idfa', idfa)
        destination.searchParams.set('nofw', nofw)
        destination.searchParams.set('fw1', fw1)
        destination.searchParams.set('fw2', fw2)
        window.location.replace(destination);
    }
    return <>
        <CryptoQuestionnaire questions={questions}
                             completitionCallback={completitionCallback}
                             debug={false}
        />
    </>
}
