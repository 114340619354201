import React from "react";
import {verifySignedTimestamp} from "../signatures";
import {ThemeProvider} from "styled-components";
import ChatBot from "react-simple-chatbot";
import {Link} from "react-router-dom";
import {BackArrow} from "../icons";
import {ClickTrughtLanding, sendEvent} from "./BotsHelpers";
import {thanksAndSeeYouSoon, TIMAcquisitionBot01} from "./connectivity/TIMAcquisitionBot01";
import {TIMAcquisitionBot02} from "./connectivity/TIMAcquisitionBot02";

// pallette: https://coolors.co/0d3028-4f1321-db728b-17755f-54c4aa
const chatbotTheme = {
    background: '#f5f8fb',
    fontFamily: 'Helvetica Neue',
    headerBgColor: '#4f1321',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#17755F',
    botFontColor: '#fff',
    userBubbleColor: '#54C4AA',
    userFontColor: '#000',
};

async function sendConversion(phoneNumber: string, url: string) {
    let data = {
        "field_data": [{
            "name": "email",
            "values": [phoneNumber.toString()]
        }, {
            "name": "phone_number",
            "values": [phoneNumber]
        }],
    }
    try {
        await fetch(url, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        })
        sendEvent("lead_completed")
    } catch {
        sendEvent('lead_rejected')
    }
}

export async function sendTimFibraConversion(phoneNumber:string) {
    await sendConversion(phoneNumber, 'https://us-central1-feisty-gasket-100715.cloudfunctions.net/tim-landing-conversion');
}

export async function sendMobileConversion(phoneNumber:string) {
    await sendConversion(phoneNumber, 'https://us-central1-feisty-gasket-100715.cloudfunctions.net/tim-mobile-conversion');
}

async function endProcessCallback(props: any) {
    const phoneNumber = props.steps.acquisitionForm03Answer?.value
    const ppAccepted = props.steps.answerForPrivacyPolicy?.value === thanksAndSeeYouSoon
    if (phoneNumber && ppAccepted) {
        await sendTimFibraConversion(phoneNumber);
    }
}


export const RandomTimAcquisitionBotForm = () => {
    const options = [TIMAcquisitionBot01, TIMAcquisitionBot02];
    const index = Math.floor(Math.random() * options.length);
    const chosen = options[index];
    sendEvent(`start-${chosen.name}`)
    return chosen()
}

interface BotProps {
    steps: any[]
    verifySignature?: boolean
    title?: string
}

export const TIMAcquisitionBot = ({steps, verifySignature, title}: BotProps) => {
    const params = new URLSearchParams(window.location.search)
    const value = params.get('_s')
    const signedTimestamp = verifySignedTimestamp(value)
    const botDelay = process.env.NODE_ENV === "development" ? 10 : 1000;
    const userDelay = process.env.NODE_ENV === "development" ? 10 : 1000;
    const customDelay = process.env.NODE_ENV === "development" ? 10 : 1000;
    let showChatbot: boolean = true
    if (verifySignature) {
        showChatbot = !!(signedTimestamp && signedTimestamp?.isValid && !signedTimestamp?.isExpired)
    }
    return (
        <>
            {showChatbot &&
                <ThemeProvider theme={chatbotTheme}>

                    <ChatBot headerTitle={title || "Inteligent Assistant"}
                             steps={steps}
                             width={"100%"}
                             handleEnd={endProcessCallback}
                             botDelay={botDelay}
                             userDelay={userDelay}
                             customDelay={customDelay}/>
                    <Link className="floatingBackButton" to="/">
                        <BackArrow fill="white"/>
                    </Link>
                </ThemeProvider>
            }
            {!showChatbot &&
                <ClickTrughtLanding title="Scopri la vantaggiosa offerta per la connettivita' casalinga"
                                    link="https://www.tim.it/fisso-e-mobile/fibra-e-adsl/premium-fibra-rl-b#wcbconfid=CE76"
                                    image={process.env.PUBLIC_URL + "/connectivity.jpg"}/>
            }
        </>
    )
}
