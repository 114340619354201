import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter as Router, Link, Route, Switch, useHistory} from "react-router-dom";
import {generateSignedTimestamp} from "./signatures";

import TagManager from 'react-gtm-module'
import {EnelAcquisitionBot} from "./bots/EnergyAcquisition";
import {Col, Container, Row} from 'reactstrap';
import {RandomTimAcquisitionBotForm, TIMAcquisitionBot} from './bots/ConnectivityAcquisition';
import {stringify} from "querystring";
import {TIMAcquisitionBot01} from "./bots/connectivity/TIMAcquisitionBot01";
import {TimAcquisitionQuestionnaire01, TimAcquisitionQuestionnaire02} from "./questionnaire/connectivityAcquisition";
import {
    TimMobileAcquisitionQuestionnaire01,
    TimMobileAcquisitionQuestionnaire02, TimMobileAcquisitionQuestionnaire04
} from "./questionnaire/mobileAcquisition";
import {CryptoQuestionnaire01} from "./questionnaire/cryptoAcquisition";
import {CustomEnergyQuestionnaire01} from "./questionnaire/customEnergy";
import { CustomEniAcquisition01 } from './questionnaire/CustomEniAcquisition01';
import {VerisureAcquisitionBot01} from "./bots/connectivity/VerisureAcquisitionBot01";
import {VerisureAcquisitionBot02} from "./bots/connectivity/VerisureAcquisitionBot02";

interface Article {
    id: number;
    cover: string;
    title: string;
    description: string;
}

function HomePageComponent() {
    const [articles, setArticles] = useState<Array<Article>>([]);

    useEffect(() => {
        (async () => {
            const limit = 12
            const response = await fetch(`https://coolnews.online/api/text/?limit=${limit}&ordering=-id`)
            const data = await response.json()
            setArticles(data.results as Array<Article>)
        })()

    }, [])

    return (
        <Container>
            <Row className="pt-2">
                <Col md="3">
                    <img className="img-fluid" src="https://storage.googleapis.com/mar-vel/assets/img/logo/logo.png"
                         alt="Cool News"/>
                </Col>
            </Row>
            <div className="navbar-nav-scroll mb-2">
                <nav className="navbar navbar-nav bd-navbar-nav flex-row">
                    <ul className="navbar-nav bd-navbar-nav flex-row">
                        <li className="nav-item">
                            <a className="nav-link" href="//coolnews.online">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://coolnews.online/category/Sport">Sport</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://coolnews.online/category/lifestyle">Lifestyle</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://coolnews.online/category/curiosita">Curiosità</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://coolnews.online/category/food">Cucina</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://coolnews.online/category/quiz">Quiz</a>
                        </li>
                    </ul>
                </nav>
            </div>

            <Row>
                {
                    articles.map((article: Article) => {
                        return <Col md={4}>
                            <div className="card">
                                <img className="card-img-top" src={article.cover} alt="Card image cap"/>
                                <div className="card-body">
                                    <h5 className="card-title">{article.title}</h5>
                                    <p className="card-text">{article.description}</p>
                                    <a href={`https://coolnews.online/article/${article.id}`}
                                       className="btn btn-primary">Continua a leggere</a>
                                </div>
                            </div>
                        </Col>
                    })
                }
            </Row>
        </Container>
    );
}

type RedirectProps = {
    url: string
}

const Redirect: React.FC<RedirectProps> = ({url}) => {
    const signedTimestamp = generateSignedTimestamp()
    let newUrl = new URL(window.location.href)
    newUrl.pathname = `${url}`
    newUrl.searchParams.set('_s', signedTimestamp)
    const history = useHistory();
    history.push(`${newUrl.pathname}${newUrl.search}`);

    return (
        <div>redirecting</div>
    )
}

function App() {
    if(!window.location.href.includes('localhost')) {
        TagManager.initialize({gtmId: 'GTM-WXWXPL3'})
    }
    const signedTimestamp = generateSignedTimestamp()
    return (
        <Router>
            <div className="App">
                <header className="App-header">
                </header>
                <div className={"min-vh-100"}>
                <Switch>
                    <Route path="/r/connectivity">
                        <Redirect url="/connectivity"></Redirect>
                    </Route>
                    <Route path="/q/ei5ooph9">
                        <TimAcquisitionQuestionnaire01/>
                    </Route>
                    <Route path="/q/idee0ohw">
                        <TimAcquisitionQuestionnaire02/>
                    </Route>
                    <Route path="/q/phaiSh3g">
                        <TimMobileAcquisitionQuestionnaire01/>
                    </Route>
                    <Route path="/q/poaSh1ie">
                        <TimMobileAcquisitionQuestionnaire02/>
                    </Route>
                    <Route path="/q/kai0olao">
                        <TimMobileAcquisitionQuestionnaire02/>
                    </Route>
                    <Route path="/q/iech2oog">
                        <TimMobileAcquisitionQuestionnaire04/>
                    </Route>
                    <Route path="/q/iefa8Hah">
                        <CryptoQuestionnaire01/>
                    </Route>
                    <Route path="/q/Ohsh2rah">
                        <CustomEnergyQuestionnaire01/>
                    </Route>
                    <Route path="/q/Zaef5ech">
                        <CustomEniAcquisition01/>
                    </Route>
                    <Route path="/q/ais7Aet0">
                        <VerisureAcquisitionBot01/>
                    </Route>
                    <Route path="/q/av0Shu7U">
                        <VerisureAcquisitionBot02/>
                    </Route>
                    <Route path="/energy">
                        <Container>
                            <Row>
                                <Col md="6" className="px-0 mx-auto">
                                    <EnelAcquisitionBot/>
                                </Col>
                            </Row>
                        </Container>
                    </Route>
                    <Route path="/connectivity">
                        <Container>
                            <Row>
                                <Col md="6" className="px-0 mx-auto">
                                    <RandomTimAcquisitionBotForm/>
                                </Col>
                            </Row>
                        </Container>
                    </Route>
                    <Route path="/list">
                        <ul>
                            <li>
                                <Link to={`/energy?_s=${signedTimestamp}`}>
                                    Enel bot 01
                                </Link>
                            </li>
                            <li>
                                <Link to={`/r/connectivity`}>
                                    TIM bot 01
                                </Link>
                            </li>
                            <li>
                                <Link to={`/q/idee0ohw`}>
                                    TIM question 01
                                </Link>
                            </li>
                            <li>
                                <Link to={`/q/ei5ooph9`}>
                                    TIM question 02
                                </Link>
                            </li>
                            <li>
                                <Link to={`/q/phaiSh3g`}>
                                    TIM mobile question 01
                                </Link>
                            </li>
                            <li>
                                <Link to={`/q/poaSh1ie`}>
                                    TIM mobile question 02
                                </Link>
                            </li>
                            <li>
                                <Link to={`/q/kai0olao`}>
                                    TIM mobile question 03
                                </Link>
                            </li>
                            <li>
                                <Link to={`/q/iech2oog`}>
                                    TIM mobile question 04
                                </Link>
                            </li>
                            <li>
                                <Link to={`/q/iefa8Hah`}>
                                    Crypto question 01
                                </Link>
                            </li>
                            <li>
                                <Link to={`/q/Ohsh2rah`}>
                                    Acquisition users energy 01
                                </Link>
                            </li>
                            <li>
                                <Link to={`/q/Zaef5ech`}>
                                    Eni Acquisition 01
                                </Link>
                            </li>
                            <li>
                                <Link to={`/q/ais7Aet0`}>
                                    Verisure acquisition 01
                                </Link>
                            </li>
                            <li>
                                <Link to={`/q/av0Shu7U`}>
                                    Verisure acquisition 02
                                </Link>
                            </li>
                        </ul>
                    </Route>
                    <Route path="/">
                        <HomePageComponent></HomePageComponent>
                    </Route>

                </Switch>
                </div>
                <Footer></Footer>
            </div>
        </Router>
    );
}

export default App;

export function Footer() {
    return <div className="footer-container">
        <div>
            <a href="https://www.iubenda.com/privacy-policy/417981" target="_blank" rel="noreferrer">Privacy Policy</a>
            -
            <a href="https://coolnews.online/terms-and-conditions" target="_blank" rel="noreferrer">Terms and
                Conditions</a>
        </div>
        <div>Copyright © 2021 Instal S.r.l. - VAT 06681730484</div>
    </div>;
}
