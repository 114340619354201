import React, {useState} from "react";
import {sendEvent} from "../bots/BotsHelpers";
import {Answer, ButtonQuestion, PhoneNumberQuestion, Question} from "./models";
import {sendTimFibraConversion} from "../bots/ConnectivityAcquisition";

export const uuidv4 = () => { // Public Domain/MIT
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
};

let question1 = new ButtonQuestion("Quanto è veloce la tua connessione attuale?",
    [new Answer("Lenta"),
        new Answer("Normale"),
        new Answer("Veloce")])
let question2 = new ButtonQuestion("Dove vivi?",
    [new Answer("Cittá"),
        new Answer("Campagna"),
        new Answer("Altro")])
let question3 = new ButtonQuestion("Sei soddisfatto della tua connessione attuale?",
    [new Answer("No"),
        new Answer("Poco"),
        new Answer("Molto")])
let question4 = new ButtonQuestion("Quanto spendi per la tua connessione?",
    [new Answer("Meno di 20€"),
        new Answer("Meno di 30€"),
        new Answer("Più i 30€")])
let question5 = new ButtonQuestion("Qual'è il tuo utilizzo principale della connessione internet?",
    [new Answer("Lavoro"),
        new Answer("Gaming"),
        new Answer("Intrattenimento")])
let question6 = new ButtonQuestion("Cosa ti interessa maggiormente nella connessione di rete?",
    [new Answer("Affidabilità "),
        new Answer("Velocità"),
        new Answer("Sicurezza")])
let question7 = new ButtonQuestion("Cosa ti interessa maggiormente nella connessione di rete?",
    [new Answer("Affidabilità "),
        new Answer("Velocità"),
        new Answer("Sicurezza")])
let question8 = new ButtonQuestion("Quanta importanza dai all'assistenza clienti del tuo provider?",
    [new Answer("Poca"),
        new Answer("Normale"),
        new Answer("Molta")])
let question9 = new ButtonQuestion("Da quante persone è composto il tuo nucleo familiare?",
    [new Answer("1 "),
        new Answer("2-3"),
        new Answer("4 o più")])
let question10 = new ButtonQuestion("Quanti dispositivi utilizzi nella tua casa?",
    [new Answer("1 "),
        new Answer("2-4"),
        new Answer("5 o più")])

let questionPhone = new PhoneNumberQuestion("Lasciaci il tuo numero di telefono",
    [], "Scrivimi il tuo numero di CELLULARE per ricevere tutte le informazioni 📲 👇🏼")

let tenQuestion = [question1, question2, question3, question4,
    question5, question6, question7, question8, question9, question10,
    questionPhone];

let fiveQuestion = [question1, question2, question5, question6,
    question10, questionPhone];


export function TimAcquisitionQuestionnaire01() {
    let questions = fiveQuestion as Question[]
    return <TimAcquisitionQuestionnaire questions={questions} conversion={sendTimFibraConversion}/>
}

export function TimAcquisitionQuestionnaire02() {
    let questions = tenQuestion as Question[]
    return <TimAcquisitionQuestionnaire questions={questions} conversion={sendTimFibraConversion}/>
}

interface QuestionnaireProps {
    questions: Question[]
    conversion: (phoneNumber: string) => Promise<void>
}

export const TimAcquisitionQuestionnaire = ({questions, conversion}: QuestionnaireProps) => {
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [context, setContext] = useState<{phone: string|null}>({phone: null})
    let leadSent = false

    let goToNext = async (answer: Answer) => {
        sendEvent(`questionnaire-answer-${currentQuestion}`)
        answer.updateContext(context)
        setContext(context)
        let newQuestion = currentQuestion + 1
        setCurrentQuestion(newQuestion)
        if (!questions[newQuestion] && context.phone !== null && !leadSent) {
            await conversion(context.phone)
            leadSent = true
        }
    }

    return (<>
            <div className="pure-g header mb-2">
                <div className="pure-u-1-1 font-size-l" style={{textAlign: "center"}}>Intelligent assistant</div>
            </div>
            <div className="pure-g ">
                <div className="pure-u-1-5 pure-hidden-md"><p></p></div>
                <div className="pure-u-1 pure-u-md-3-5">
                    {questions[currentQuestion] &&
                        questions[currentQuestion].render(goToNext)
                    }
                    {!questions[currentQuestion] &&
                        <div className="m-5">
                            <p className="text-center font-size-l font-weight-bold">
                                Grazie per averci lasciato le tue informazioni.
                            </p>
                            <p className="text-center">
                                Stiamo elaborando la tua richiesta per ricontattarti il prima possibile e illustrarti la
                                nostra offerta
                            </p>
                        </div>
                    }
                </div>
                <div className="pure-u-1-5 pure-hidden-md"><p></p></div>
            </div>
        </>
    )
}
